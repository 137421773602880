
import { IAvailablePromoPackage } from "@/interfaces/mlm";
import { Component, Vue, Prop } from "vue-property-decorator";
import {IUserProfile} from "@/interfaces";
import {dispAuthFetch} from "@/store/mlm/actions";

@Component({
  components: {},
})
export default class GeneratePrivateKeyDexIdModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;
  public packages: IAvailablePromoPackage[] = [];
  public loadingState: any = {
    packages: true,
    submit: false,
  };
  public isModalClosed: boolean = false;
  public promocode: string | null = null;
  public user: IUserProfile | null = null;
  public promoCodeList = []


  public async mounted() {
    const invoiceResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/dexnet_integration/dexid-promocodes/",
      init: {
        method: "POST",
      },
    });

    if (invoiceResp.status === 200) {
      this.promoCodeList = await invoiceResp.json()
    } else {
      this.$toast.error("Failed to create invoice, please try again later");
    }
  }

  public closeModal() {
    this.isModalClosed = true;
    this.$emit("close-modal");
  }
}
