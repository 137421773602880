<template>
  <div class="progress-bar">
    <div
        class="progress-bar__fill"
        :style="{ width: progressWidth }"
    ></div>
    <div class="progress-bar__stages">
      <span
          v-for="(stage, index) in stages"
          :key="index"
          :class="{ active: currentStage >= index }"
      >
        {{ stage }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStage: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 3
    }
  },
  computed: {
    progressWidth() {
      return `${(this.currentStage) * (100 / 3)}%`; // 0 → 0%, 1 → 33%, 2 → 66%, 3 → 100%
    },
    stages() {
      return ['Старт', 'Процесс', 'Почти готово', 'Готово'];
    }
  }
};
</script>

<style scoped>
.progress-bar {
  width: 100%;
  background: #ccc;
  height: 10px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.progress-bar__fill {
  height: 100%;
  background: #00d1ff;
  transition: width 0.3s ease-in-out;
}

.progress-bar__stages {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 14px;
  color: #666;
}

.progress-bar__stages span {
  position: relative;
  font-weight: bold;
}

.progress-bar__stages span.active {
  color: #00d1ff;
}
</style>
