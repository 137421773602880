
import { IAvailablePromoPackage } from "@/interfaces/mlm";
import { readUserProfile } from "@/store/main/getters";
import { Component, Vue, Prop } from "vue-property-decorator";
import {IUserProfile} from "@/interfaces";
import KeysDexIdModal from "@/components/Main/Transactions/KeysDexIdModal.vue";
import {dispAuthFetch} from "@/store/mlm/actions";

@Component({
  components: {KeysDexIdModal},
})
export default class GeneratePrivateKeyDexIdModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;
  public packages: IAvailablePromoPackage[] = [];
  public loadingState: any = {
    packages: true,
    submit: false,
  };
  public isModalClosed: boolean = false;
  public isKeysDexIdModal: boolean = false;
  public promocode: string | null = null;
  public user: IUserProfile | null = null;

  public keyNode: string = "";
  public name: string = "";
  public surname: string = "";
  public email: string = "";
  public errorMessage = ""


  public async mounted() {
    this.user = readUserProfile(this.$store);
  }

  public closeModal() {
    this.isModalClosed = true;
    this.$emit("close-modal");
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public async createKey() {
    const invoiceResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/dexnet_integration/create-dexid-promocode/",
      init: {
        method: "POST",
        body: {
          promocode_type: "private",
          "user_id": this?.userProfile?.id,
          "device_key": this.keyNode,
          "email": this.email,
          "first_name": this.name,
          "last_name": this.surname
        },
      },
    });

    if (invoiceResp && invoiceResp.status === 200) {
      this.$toast.success(this.$t('Promo code activated successfully').toString());
    } else if(invoiceResp && invoiceResp.status === 400) {
      this.$toast.error("Check the correctness of filling");
    }
  }
}
